<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value">mdi-view-quilt</v-icon>

      <v-icon v-else>mdi-arrow-collapse-horizontal</v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$t($route.name)"
    />

    <v-spacer />

    <v-text-field
      v-if="searchEnabled"
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 165px"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <div class="mx-3" />

    <dashboard-core-settings />

    <div class="mx-3" />
    <locale-changer />

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list :tile="false" nav>
        <v-list-item @click="$router.push('/user')">
          <v-list-item-title>{{ loggedInUser.name }} profile</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import LocaleChanger from "./LocaleChanger";
// Utilities
import { mapState, mapMutations } from "vuex";

import DashboardCoreSettings from "./Settings";

export default {
  name: "DashboardCoreAppBar",
  components: {
    LocaleChanger,
    DashboardCoreSettings,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    accentColor: "#f00",
    searchEnabled: false,
    languages: [
      {
        value: "en",
        text: "English",
      },
      {
        value: "de",
        text: "German",
      },
    ],
  }),

  computed: {
    ...mapState(["drawer"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    logout() {
      localStorage.removeItem("loggedInUser");
      this.post("/logout");
      this.$router.push("/login");
    },
  },
};
</script>
