<template>
  <v-menu bottom left offset-y origin="top right" transition="scale-transition" v-if="multilanguage>0">
    <template v-slot:activator="{ on }">
      <v-chip pill v-on="on">{{ currentLocale }}</v-chip>
    </template>
    <v-list :tile="false" nav>
      <v-list-item
        v-for="(n, i) in languages"
        :key="`item-${i}`"
        @click="$i18n.locale = n.value"
      >
        <v-list-item-title v-text="n.text" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "LocaleChanger",
  data() {
    return {
      multilanguage: process.env.VUE_APP_I18N_MULTI || 0,
      languages: [
        {
          value: "en",
          text: "English",
        },
        {
          value: "de",
          text: "Deutsch",
        },
      ],
    };
  },
  computed: {
    currentLocale() {
      for (const l in this.languages) {
        if (this.languages[l].value === this.$i18n.locale) {
          return this.$t(this.languages[l].text);
        }
      }
      return this.$i18n.locale;
    },
  },
};
</script>
