<template>
  <div id="settings-wrapper">
    <v-btn id="settings" class="ml-2" min-width="0" text>
      <v-icon>mdi-invert-colors</v-icon>
    </v-btn>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#settings"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="top right"
      transition="scale-transition"
    >
      <v-card class="text-center mb-0" width="300">
        <v-card-text>
          <v-item-group v-model="color">
            <v-item v-for="color in colors" :key="color" :value="color">
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="color"
                  class="v-settings__item"
                  size="30"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4" />

          <v-row align="center" no-gutters>
            <v-col cols="auto"> {{ $t("dark mode") }} </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch v-model="dark" class="ma-0 pa-0" hide-details />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
// Mixins
import Proxyable from "vuetify/lib/mixins/proxyable";
import { mapMutations, mapState } from "vuex";

export default {
  name: "DashboardCoreSettings",

  mixins: [Proxyable],

  data: () => ({
    dark: false,
    color: "#E91E63",
    colors: [
      "#9C27B0",
      "#2196F3",
      "#009688",
      "#4CAF50",
      "#AFB42B",
      "#FF5722",
      "#607D8B",
    ],
    menu: false,
  }),

  mounted() {
    this.dark = this.$vuetify.theme.dark = this.theme == "dark";
    this.$vuetify.theme.themes[
      this.isDark ? "dark" : "light"
    ].primary = this.primaryColor;
  },
  computed: {
    ...mapState(["theme", "primaryColor"]),
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  watch: {
    dark(val) {
      if (val) {
        this.$vuetify.theme.dark = true;
        this.setTheme("dark");
      } else {
        this.$vuetify.theme.dark = false;
        this.setTheme("light");
      }
      this.$vuetify.theme.themes[
        this.isDark ? "dark" : "light"
      ].primary = this.primaryColor;
    },
    color(val) {
      this.setColor(val);
      this.$vuetify.theme.themes[this.isDark ? "dark" : "light"].primary = val;
    },
  },

  methods: {
    ...mapMutations({
      setTheme: "SET_THEME",
      setColor: "SET_COLOR",
    }),
  },
};
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 2px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #18FFFF !important
.v-input--switch
  .primary--text
    color: #BDBDBD!important
    caret-color: #CFCFCF!important
</style>
